<template>
    <footer id="footer" class="border-top-0 m-0 lazyload" data-bg-src="/img/demos/business-consulting-3/backgrounds/background-4.jpg" style="background-size: cover; background-position: center; background-repeat: no-repeat;">
        <div class="container pt-3">
            <div class="row justify-content-between align-items-center py-5 mb-3">
                <div class="col-auto mb-4 mb-lg-0">
                    <h2 class="font-weight-semibold text-color-light text-10 ls-0 mb-0">Global Import Services</h2>
                </div>
                <div class="col-auto">
                    <router-link to="/contact" class="btn btn-primary custom-btn-style-1 font-weight-medium btn-px-4 btn-py-2 text-4" data-cursor-effect-hover="plus" data-cursor-effect-hover-color="light">
                        <span class="text-color-light">Contáctanos</span>
                    </router-link>
                </div>
            </div>
            <hr class="bg-color-light opacity-1 my-0">
            <div class="row pt-3 mt-5">
                <div class="col-lg-3 mb-4 mb-lg-0">
                    <router-link to="/" class="text-decoration-none">
                        <img src="/img/logos/logo.png" class="img-fluid mb-4" width="123" height="33" alt="" />
                    </router-link>
                    <p class="text-3-5">Crea oportunidades globales y haz crecer tu negocio con importaciones seguras y fáciles. </p>
                    <ul class="social-icons social-icons-clean social-icons-clean-with-border social-icons-medium social-icons-icon-light">
                        <li class="social-icons-instagram"><a href="http://www.instagram.com/globalimportservices" target="_blank" title="Instagram" data-cursor-effect-hover="fit"><i class="fab fa-instagram"></i></a></li>
                        <li class="social-icons-tiktok mx-2"><a href="http://www.tiktok.com/" target="_blank" title="Tiktok" data-cursor-effect-hover="fit"><i class="fab fa-tiktok"></i></a></li>
                    </ul>
                </div>
                <div class="col-lg-6 mb-4 mb-lg-0" style="text-align: end;">
                    <ul class="list list-icons list-icons-lg">
                        <li class="d-flex px-0 my-1">
                            <img width="25" src="/img/demos/business-consulting-3/icons/email.svg" alt="Email Icon" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-light'}" />
                            <a href="mailto:asesoria@globalimportservices.com" class="text-color-light font-weight-semibold text-3-4 ms-2">asesoria@globalimportservices.com</a>
                        </li>
                        <li class="d-flex px-0 mb-1">
                            <img width="25" src="/img/demos/business-consulting-3/icons/phone.svg" alt="Phone Icon" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-light'}" />
                            <a href="tel:+593994805867" class="text-color-light font-weight-semibold text-3-4 ms-2">+593 994805867</a>
                        </li>
                        <li class="d-flex px-0 mb-1">
                            <img width="25" src="/img/demos/business-consulting-3/icons/phone.svg" alt="Phone Icon" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-light'}" />
                            <a href="tel:+593963002799" class="text-color-light font-weight-semibold text-3-4 ms-2">+593 963002799</a>
                        </li>
                        <li class="d-flex font-weight-semibold text-color-light px-0 mb-1">
                            <img width="25" src="img/demos/business-consulting-3/icons/map-pin.svg" alt="Location" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-light me-2'}" />
                            Ciudad Celeste Etapa La Península, Samborondón, Ecuador
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-copyright container bg-transparent">
            <div class="row pb-5">
                <div class="col-lg-12 text-center m-0">
                    <hr class="bg-color-light opacity-1 mt-5 mb-4">
                    <p class="text-3-4">Global Import Services. © 2023. Todos los derechos reservados</p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script src="./FooterSection.ts"/>