import emailjs from 'emailjs-com';
const twoDaysMillis = 172800000;

export default {
    name: 'ContactForm',
    data() {
        return {
            contactForm: {
                name: "",
                email: "",
                message: "",
            }
        }
    },
    methods: {
        send() {
            let fieldsValidation = this.validateForm();
            if(fieldsValidation.length > 0) {
                fieldsValidation.forEach(element => {
                    this.$refs[element].classList.add("show");
                });
            } else {
                const lastMessageSent = localStorage.getItem("lastMessageSent") ? parseInt(localStorage.getItem("lastMessageSent")) : 0;
                let dateDiff = Date.now() - lastMessageSent;
                if (dateDiff > twoDaysMillis) {
                    try {
                        emailjs.send("gis_mail_service", "template_ewtzg6h", {
                            name: this.contactForm.name,
                            email: this.contactForm.email,
                            message: this.contactForm.message
                        }, "GaY8tzzFs4WRoYHtg").then(() => {
                            this.handleMessageSent();
                        });
                    } catch(error) {
                        alert("Lo sentimos, ha ocurrido un error al enviar tu mensaje. Recuerda que nos puedes escribir directamente a asesoria@globalimportservices.com");
                    }
                } else {
                    this.handleMessageSent();
                }
            }
        },
        handleMessageSent() {
            localStorage.setItem("lastMessageSent", Date.now().toString());
            alert("Gracias por contactarte con nosotros. Responderemos a tu email lo antes posible.");
            this.contactForm.name = "";
            this.contactForm.email = "";
            this.contactForm.message = "";
        },
        validateForm() {
            let errorFields = []
            if (this.contactForm.name == "") {
                errorFields.push("contact-name");
            }

            if (this.contactForm.email == "" || !this.emailValidation(this.contactForm.email)) {
                errorFields.push("contact-email");
            }

            if (this.contactForm.message == "") {
                errorFields.push("contact-message");
            }
            console.log(errorFields)
            return errorFields;
        },
        focusInput(refId) {
            this.$refs[refId].classList.remove("show");
        },
        emailValidation(email) {
            return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
        }
    }
}