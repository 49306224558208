<template>
      <body data-plugin-cursor-effect>
		<div class="body">
			<div role="main" class="main">

				<section class="section section-height-3 section-with-shape-divider main-section position-relative border-0 m-0" data-plugin-parallax data-plugin-options="{'speed': 1.5, 'parallaxHeight': '120%'}" data-image-src="/img/global-bg.jpg" style="padding-bottom: 0;">
					<div class="container pb-2 pb-xl-0 mt-md-3 mb-2">
						<div class="row">
							<div class="col-md-7 col-lg-12">
								<div class="appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="0">
									<h1 class="custom-text-background custom-big-font-size-1 text-14 font-weight-bold float-xl-end clearfix line-height-1 custom-ws-mobile-wrap ws-nowrap pb-2 mb-3 mb-xl-5" style="color: black; font-size: 5rem!important; float: left!important;">GLOBAL IMPORT SERVICES</h1>
								</div>
							</div>
						</div>
						<div class="row pb-2 mb-2">
							<div class="col-md-7 col-xl-5 pb-5 pb-xl-0 mb-5">
								<strong class="d-block font-weight-semibold text-color-dark text-5-5 line-height-4 mb-3 pb-1 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500">Crea oportunidades globales y haz crecer tu negocio con <span class="custom-highlight-text-1 font-weight-bold">Importaciones Seguras y Fáciles</span></strong>
								<p class="text-3-5 mb-4 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="750" style="color:black; font-weight: 400;">Contáctate con nosotros para realizar tus importaciones. </p>
								<a href="#aboutus" data-hash data-hash-offset="0" data-hash-offset-lg="100" class="d-inline-flex align-items-center text-decoration-none appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1000">
									<img width="30" height="30" src="/img/demos/business-consulting-3/icons/mouse.svg" alt="" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary me-2'}" />
									Desliza
								</a>
							</div>
						</div>
					</div>
					<div class="animated-divider-background">
						<div class="shape-divider shape-divider-bottom" style="height: 212px; top: 0;">
							<div class="shape-divider-horizontal-animation shape-divider-horizontal-animation-to-left">
								<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 212" preserveAspectRatio="xMinYMin">
									<polygon fill="#F3F3F3" points="0,75 479,161 1357,28 1701,56 1920,26 1920,213 0,212 "/>
									<polygon fill="#FFFFFF" points="0,91 481,177 1358,44 1702,72 1920,42 1920,212 0,212 "/>
								</svg>
							</div>
						</div>
					</div>
				</section>

				<section class="section section-height-3 position-relative border-0 m-0 pt-2" style="background-color: white;">
					<div class="container">
						<div id="aboutus" class="row align-items-xl-center">
							<div class="col-md-10 col-lg-6 mb-5 mb-lg-0">
								<div class="row row-gutter-sm">
									<div class="col-6">
										<img src="/img/about/about1.png" class="img-fluid box-shadow-5" alt="" />
									</div>
									<div class="col-6">
										<img src="/img/about/about2.png" class="img-fluid box-shadow-5 mb-4" alt="" />
										<img src="/img/about/about3.png" class="img-fluid box-shadow-5" alt="" />
									</div>
								</div>
							</div>
							<div class="col-lg-6 ps-lg-4 ps-xl-5">
								<h2 class="custom-highlight-text-1 d-inline-block line-height-5 text-4 positive-ls-3 font-weight-medium text-color-primary mb-2 appear-animation" data-appear-animation="fadeInUpShorter">ACERCA DE NOSOTROS</h2>
								<h3 class="text-9 text-lg-5 text-xl-9 line-height-3 text-transform-none font-weight-semibold mb-4 mb-lg-3 mb-xl-4 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250">Proporcionamos servicios de importación de primera clase</h3>
								<p class="text-3-5 pb-1 mb-4 mb-lg-2 mb-xl-4 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500">En Global Import Services, nos dedicamos a proporcionar servicios de importación de primera clase que garantizan la seguridad, la eficiencia y la tranquilidad de nuestros valiosos clientes. Con nuestra experiencia en logística y negociación global, ofrecemos soluciones integrales para importar mercancías desde cualquier parte del mundo. </p>
								<div class="appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1000">
									<router-link to="/contact" class="btn btn-primary custom-btn-style-1 font-weight-semibold btn-px-4 btn-py-2 text-3-5" data-cursor-effect-hover="plus" data-cursor-effect-hover-color="light"><span>Solicita tu asesoría</span></router-link>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section class="section section-height-4 section-with-shape-divider position-relative bg-dark border-0 m-0">
					<div class="shape-divider shape-divider-reverse-x z-index-3" style="height: 102px;">
						<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 102" preserveAspectRatio="xMinYMin">
							<polygon fill="#F3F3F3" points="0,65 220,35 562,63 931,10 1410,68 1920,16 1920,103 0,103 "/>
							<polygon fill="#FFF" points="0,82 219,51 562,78 931,26 1409,83 1920,32 1920,103 0,103 "/>
						</svg>
					</div>
					<div class="position-absolute top-0 left-0 h-100 d-none d-lg-block overlay overlay-show overlay-color-primary" data-plugin-parallax data-plugin-options="{'speed': 1.5}" data-image-src="/img/demos/business-consulting-3/parallax/parallax-1.jpg" style="width: 40%;"></div>
					<div class="container position-relative z-index-3 pt-5 mt-5">
						<div class="row align-items-center">
							<div class="col-lg-6">
								<h2 class="custom-text-background custom-big-font-size-1 text-15 font-weight-bold float-end clearfix line-height-1 lazyload pe-xl-5 me-3 mb-0 d-none d-lg-block" data-plugin-float-element data-plugin-options="{'startPos': 'top', 'speed': 0.6, 'transition': true, 'horizontal': true, 'transitionDuration': 1000, 'isInsideSVG': true}" style="color: #f7f7f7">BENEFICIOS</h2>
							</div>
							<div class="col-lg-6">
								<h2 class="custom-highlight-text-1 d-inline-block line-height-5 text-4 positive-ls-3 font-weight-medium text-color-primary mb-2 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250">POR QUÉ NOSOTROS</h2>
								<h3 class="text-9 line-height-3 text-transform-none font-weight-medium text-color-light ls-0 mb-3 pb-1 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500">Conoce las principales razones de por qué elegirnos</h3>
								<p class="text-3-5 pb-2 mb-4 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="750">Traemos amplia experiencia en la industria de importación. Nuestro equipo está formado por profesionales bien versados en logística global, gestión de proveedores, regulaciones aduaneras y todos los aspectos del proceso de importación. </p>
								<ul class="list ps-0 pe-lg-5 mb-0">
									<li class="d-flex align-items-start pb-1 mb-3 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1000">
										<i class="fas fa-check text-color-light text-4 custom-bg-color-grey-1 rounded-circle p-3"></i>
										<span class="text-3-5 ps-3">Con nuestro conocimiento y know-how, navegamos por las complejidades del comercio internacional para ofrecer servicios de importación eficientes y confiables.</span>
									</li>
									<li class="d-flex align-items-start pb-1 mb-3 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1250">
										<i class="fas fa-check text-color-light text-4 custom-bg-color-grey-1 rounded-circle p-3"></i>
										<span class="text-3-5 ps-3">Desde la búsqueda de proveedores y productos hasta el almacenamiento y la entrega, ofrecemos soluciones integrales de importación.
										</span>
									</li>
									<li class="d-flex align-items-start pb-1 mb-3 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1250">
										<i class="fas fa-check text-color-light text-4 custom-bg-color-grey-1 rounded-circle p-3"></i>
										<span class="text-3-5 ps-3">Nuestros servicios están diseñados para ser integrales, garantizando que cada paso del proceso de importación se maneje sin problemas.
										</span>
									</li>
									<li class="d-flex align-items-start appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1500">
										<i class="fas fa-check text-color-light text-4 custom-bg-color-grey-1 rounded-circle p-3"></i>
										<span class="text-3-5 ps-3">Al elegirnos, obtiene acceso a una ventanilla única para todas sus necesidades de importación, eliminando la necesidad de múltiples proveedores de servicios y simplificando sus operaciones.</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</section>

				<section class="section section-height-4 section-with-shape-divider bg-color-grey-scale-1 border-0 pb-5 m-0">
					<div class="shape-divider" style="height: 123px;">
						<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 123" preserveAspectRatio="xMinYMin">
							<polygon fill="#F3F3F3" points="0,90 221,60 563,88 931,35 1408,93 1920,41 1920,-1 0,-1 "/>
							<polygon fill="#212529" points="0,75 219,44 563,72 930,19 1408,77 1920,25 1920,-1 0,-1 "/>
						</svg>
					</div>
					<div class="container mt-4">
						<div class="row justify-content-center">
							<div class="col-lg-11 col-xl-10 text-center">
								<h2 class="custom-highlight-text-1 d-inline-block line-height-5 text-4 positive-ls-3 font-weight-medium text-color-primary mb-2 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250">SERVICIOS QUE OFRECEMOS</h2>
								<h3 class="text-9 line-height-3 text-transform-none font-weight-semibold mb-3 pb-1 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500">Servicios Integrales</h3>
							</div>
						</div>
						<div class="row row-gutter-sm justify-content-center mb-5 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1000">
							<div v-for="(service, index) in mainServices" :key="index" class="col-sm-9 col-md-6 col-lg-4 mb-4">
								<div class="card box-shadow-4">
									<div class="card-img-top position-relative overlay overlay-show">
										<div class="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">
											<h4 class="font-weight-semibold text-color-light text-6 mb-1">{{ service.name }}</h4>
											<div class="custom-crooked-line">
												<img width="154" height="26" src="/img/demos/business-consulting-3/icons/infinite-crooked-line.svg" alt="" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}" style="width: 154px;" />
											</div>
										</div>
										<img :src="'/img/services/'+service.image"  class="img-fluid service-img" alt="Card Image" />
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col text-center">
								<router-link to="/services" class="btn btn-primary custom-btn-style-1 font-weight-semibold btn-px-4 btn-py-2 text-3-5" data-cursor-effect-hover="plus" data-cursor-effect-hover-color="light">
									<span>Ver Más</span>
								</router-link>
							</div>
						</div>
					</div>
				</section>
				<contact-form/>
			</div>
		</div>
    </body>
</template>
<script src="./MainPage.ts"/>
<style src="./MainPage.css"/>