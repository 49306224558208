<template>
  <nav-bar/>
  <router-view :key="$route.fullPath"/>
  <footer-section/>
</template>

<script>
import NavBar from './components/nav-bar/NavBar';
import FooterSection from './components/footer-section/FooterSection';

export default {
  name: 'App',
  components: {
    NavBar,
    FooterSection
  }
}
</script>
