import { createRouter, createWebHistory } from 'vue-router'
import MainPage from '@/pages/main-page/MainPage'

const routes = [
  {
    path: '/',
    name: 'main',
    component: MainPage
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../pages/about-us/AboutUs')
  },
  {
    path: '/services',
    name: 'services',
    component: () => import(/* webpackChunkName: "about" */ '../pages/services-page/ServicesPage')
  },
  {
    path: '/cases',
    name: 'cases',
    component: () => import(/* webpackChunkName: "about" */ '../pages/cases-page/CasesPage')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../pages/contact-page/ContactPage')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
