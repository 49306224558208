<template>
    <header id="header" class="header-effect-shrink" data-plugin-options="{'stickyEnabled': true, 'stickyEffect': 'shrink', 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': false, 'stickyChangeLogo': true, 'stickyStartAt': 120, 'stickyHeaderContainerHeight': 85}">
        <div class="header-body border-top-0">
            <div class="header-top header-top-default header-top-borders border-bottom-0 bg-color-light">
                <div class="container">
                    <div class="header-row">
                        <div class="header-column justify-content-between">
                            <div class="header-row">
                                <nav class="header-nav-top w-100 w-md-50pct w-xl-100pct">
                                    <ul class="nav nav-pills d-inline-flex custom-header-top-nav-background pe-5">
                                        <li class="nav-item py-2 d-inline-flex z-index-1">
                                            <span class="d-flex align-items-center p-0">
                                                <span>
                                                    <img width="25" src="/img/demos/business-consulting-3/icons/phone.svg" alt="Phone Icon" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-light'}" />
                                                </span>
                                                <a class="text-color-light text-decoration-none font-weight-semibold text-3-5 ms-2" href="tel:+593994805867" data-cursor-effect-hover="plus" data-cursor-effect-hover-color="light">+593 994805867</a>
                                            </span>
                                            <span class="font-weight-normal align-items-center px-0 d-none d-xl-flex ms-3">
                                                <span>
                                                    <img width="25" src="/img/demos/business-consulting-3/icons/email.svg" alt="Email Icon" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-light'}" />
                                                </span>
                                                <a class="text-color-light text-decoration-none font-weight-semibold text-3-5 ms-2" href="mailto:asesoria@globalimportservices.com" data-cursor-effect-hover="plus" data-cursor-effect-hover-color="light">asesoria@globalimportservices.com</a>
                                            </span>
                                        </li>
                                    </ul>
                                </nav>
                                <div class="d-flex align-items-center w-100">
                                    <ul class="ps-0 ms-auto mb-0">
                                        <li class="nav-item font-weight-semibold text-1 text-lg-2 text-color-dark d-none d-md-flex justify-content-end me-3">
                                            Lun - Sáb 9:00am - 6:00pm / Domingo - CERRADO
                                        </li>
                                    </ul>
                                    <ul class="social-icons social-icons-clean social-icons-icon-dark social-icons-big m-0 ms-lg-2">
                                        <li class="social-icons-instagram">
                                            <a href="https://instagram.com/globalimportservices/" target="_blank" class="text-4" title="Instagram" data-cursor-effect-hover="fit"><i class="fab fa-instagram"></i></a>
                                        </li>
                                        <li class="social-icons-tiktok">
                                            <a href="https://www.tiktok.com/" target="_blank" class="text-4" title="Tiktok" data-cursor-effect-hover="fit"><i class="fab fa-tiktok"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="header-container container" style="height: 117px;">
                <div class="header-row">
                    <div class="header-column">
                        <div class="header-row">
                            <div class="header-logo">
                                <router-link to="/">
                                    <img alt="Global Import Services Logo" src="/img/logos/logo.png" style="height: 60px; width: auto;">
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="header-column justify-content-end w-100">
                        <div class="header-row">
                            <div class="header-nav header-nav-links order-2 order-lg-1">
                                <div class="header-nav-main header-nav-main-square header-nav-main-text-capitalize header-nav-main-effect-1 header-nav-main-sub-effect-1">
                                    <nav class="collapse">
                                        <ul class="nav nav-pills" id="mainNav">
                                            <li>
                                                <router-link :class="{'nav-link': true, 'active': $route.name == 'main'}" to="/">Inicio</router-link>
                                            </li>
                                            <li>
                                                <router-link :class="{'nav-link': true, 'active': $route.name == 'about'}" to="/about">Nosotros</router-link>
                                            </li>
                                            <li>
                                                <router-link :class="{'nav-link': true, 'active': $route.name == 'services'}" to="/services">Servicios</router-link>
                                            </li>
                                            <li>
                                                <router-link :class="{'nav-link': true, 'active': $route.name == 'cases'}" to="/cases">Casos</router-link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="header-column header-column-search justify-content-end align-items-center d-flex w-auto flex-row">
                        <router-link to="/contact" class="btn btn-dark custom-btn-style-1 font-weight-semibold text-3-5 btn-px-3 py-2 ws-nowrap ms-4 d-none d-lg-block" data-cursor-effect-hover="plus" data-cursor-effect-hover-color="light"><span>Contacto</span></router-link>
                        <button class="btn header-btn-collapse-nav" data-bs-toggle="collapse" data-bs-target=".header-nav-main nav">
                            <i class="fas fa-bars"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script src="./NavBar.ts"/>