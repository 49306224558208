import ContactForm from "@/components/contact-form/ContactForm";
import initTheme from "@/utils/js/theme.init";

export default {
    name: 'MainPage',
    components: {
        ContactForm
    },
    mounted() {
        initTheme.apply(this, [jQuery]);
    },
    data() {
        return {
            mainServices: [
                {
                    name: "Búsqueda de productos y proveedores",
                    image: "service1.png",
                    text: "Cras a elit sit amet leo accumsan. Suspendisse hendrerit."
                },
                {
                    name: "Inspección de mercadería a importarse",
                    image: "service2.png",
                    text: "Cras a elit sit amet leo accumsan. Suspendisse hendrerit."
                },
                {
                    name: "Coordinación de logística integral",
                    image: "service3.png",
                    text: "Cras a elit sit amet leo accumsan. Suspendisse hendrerit."
                },
                {
                    name: "Negociación con proveedores",
                    image: "service4.png",
                    text: "Cras a elit sit amet leo accumsan. Suspendisse hendrerit."
                },
                {
                    name: "Registro como importadores",
                    image: "service5.png",
                    text: "Cras a elit sit amet leo accumsan. Suspendisse hendrerit."
                },
                {
                    name: "Asesoría en sus importaciones",
                    image: "service6.png",
                    text: "Cras a elit sit amet leo accumsan. Suspendisse hendrerit."
                }
            ]
        }
    }
}